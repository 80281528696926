import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from '../components/grid'
import PageHeader from '../components/page-header'
import styled, { Box, up, css } from '@xstyled/styled-components'
import { Text, Emphasis } from '../components/text'
import SubHeader from '../components/sub-header'
import Button from '../components/button'
import SquiggleBox from '../components/squiggle-box'
import { navigate } from 'gatsby'
import yellowArc from '../images/yellow-arc.svg'

const ArcContainer = styled(Box)`
  ${up(
    'md',
    css`
      background-image: url(${yellowArc});
      background-repeat: no-repeat;
      background-position: bottom right;
    `
  )}
`

const CtaBox = styled(Box)`
  margin: 6 0 7;
  text-align: center;
`

const CtaButton = styled(Button)`
  margin: 5 auto;
`

const Investment = () => (
  <>
    <SEO title="Invest With Us" />
    <Layout>
      <PageHeader>Invest With Us</PageHeader>
      <ArcContainer>
        <Container pt={{ xs: 5, md: 5, lg: 6 }} position="relative">
          <SquiggleBox
            variant="blue"
            positioning={{ md: 'left', lg: 'left' }}
          />
          <Row>
            <Col col={{ xs: 1, md: 6 / 8, lg: 8 / 12 }} mx="auto">
              <SubHeader>
                As recently exited founders, we have access to the best UK tech
                entrepreneurs.
              </SubHeader>
            </Col>
            <Col
              col={{ xs: 1, md: 5 / 8, lg: 6 / 12 }}
              mx="auto"
              textAlign={{ xs: 'center', md: 'left' }}
            >
              <Text>
                After founding and building a multi-million pound startup we are
                now investor operators, and know exactly what it takes to
                succeed in the startup world. Better yet, we also have a proven
                track record for sniffing out realistic, but exciting and
                scalable business plans.
              </Text>
              <Text>
                So with that in mind, launching the
                <Emphasis mx={1}>Horseplay Investment Club</Emphasis> in order to
                build the best active list of Angel Investors in the UK and
                Europe felt like a no brainer.
              </Text>
              <Text>
                Having exited our own successful startup in 2020, our network is
                varied, agile and full of potential. We get exposure to the
                cream of the crop of UK founders and sift through hundreds of
                pitch decks weekly to unearth the ones that really get our
                juices flowing - and with our Investment Club, you’ll be privy to
                those too; all with an honest and transparent assessment by us
                included.
              </Text>
              <Text>
                Skin in the game is important to us,
                <Emphasis mx={1}>
                  so we personally invest in every company that is sent out to
                  the Horseplay Investment Club.
                </Emphasis>
                We take no fees, and there are no associated carry fees for the
                deals either. The sole goal of the Club is to help our
                investments raise money easier and quicker. If you like the
                look of the company we introduce you to the founder(s) and step
                away.
              </Text>
              <Text>
                If you are an active Angel Investor, fund, family office or high
                net worth individual - get in touch.
              </Text>
              <CtaBox>
                <a href="https://form.jotform.com/221602560427347" target="_blank"rel="noopener noreferrer">
                  <CtaButton>Ready to join us?</CtaButton>
                </a>
              </CtaBox>
            </Col>
          </Row>
        </Container>
      </ArcContainer>
    </Layout>
  </>
)

export default Investment
